import { handleSubmitForm } from './plugins/handleSubmitForm';
import { faq } from './plugins/faq';
import { profit } from './plugins/profit';
import { handleToTop } from './toTop';

(function( $ ) {
  window.addEventListener('DOMContentLoaded', function () {
    handleSubmitForm();
    faq();
    profit();
    handleToTop();

    $('#menu-button').on('click', function () {
      $('.nav-wrapper').toggle();
      $('#menu-button').toggleClass('close');
    });

    $(window).resize(function(){
      if(window.innerWidth > 991) {
        $('.nav-wrapper').show();
        $('#menu-button').addClass('close');
      } else {
        $('.nav-wrapper').hide();
        $('#menu-button').removeClass('close');
      }
    });

    $("#lang-icon").click(function () {
      $("#lang-switch").slideToggle(0);
    });
  });

  $(document).mouseup(function (e) {
    var container = $("#lang-switch");
    if (container.has(e.target).length === 0){
      container.hide();
    }
  });
})( jQuery );

function setCookie(cname, cvalue, exdays) {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires="+currentDate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  const name = cname + "=";
  const cookies = document.cookie.split(';');
  for(let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}
